import React,{useEffect} from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, useLocation } from "react-router-dom";


import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import {ThemeProvider, createTheme} from '@material-ui/core/styles';

import App from './App';
import reportWebVitals from './reportWebVitals';

const themeMui = createTheme({
  palette: {
    root: {
      main: '#5CDB95'
    },
    primary: {
      main: '#05386B'
    },
    secondary: {
      main: '#5CDB95',
    },
    test: '#5CDB95'
  }
});

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.getElementsByTagName('html')[0].style.removeProperty('scroll-behavior');
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
    <ScrollToTop />
      <ThemeProvider theme = {themeMui}>
        <App />
      </ThemeProvider> 
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
